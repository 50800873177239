:global(portainer-tooltip) {
  @apply inline-flex;
}

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip {
  background-color: var(--bg-tooltip-color) !important;
  padding: 0.833em 1em !important;
  color: var(--text-tooltip-color) !important;
  border-radius: 10px !important;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  max-width: 400px;
  text-align: center;
  font-size: 12px !important;
  font-weight: 400;

  white-space: normal;
  word-break: break-word;
}

.icon {
  margin-left: 5px;
  cursor: pointer;
}
